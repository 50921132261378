
import Vue from "vue";
// @ts-ignore
import VerticalNav from "@/components/Content/Customize/VerticalNav.vue";
// @ts-ignore
import FieldSelector from "@/components/Content/Customize/FieldSelector.vue";
// @ts-ignore
import FieldSelected from "@/components/Content/Customize/FieldSelected.vue";
// @ts-ignore
import SliderColumns from "@/components/Content/Customize/SliderColumns.vue";

import { mapGetters } from "vuex";
import { union } from "lodash";

export default Vue.extend({
	props: {
		isOpen: {
			type: Boolean,
			default: false,
		},
		selected: {
			type: Object,
			default: function () {
				return {};
			},
		},
		fields_default: {
			type: Array,
			default: function () {
				return [];
			},
		},
		tableHeaders: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},
	components: {
		VerticalNav,
		FieldSelector,
		FieldSelected,
		SliderColumns,
	},

	data: () => ({
		current_tab: 0,
		term: null,
		fields: {},
		view_content: {
			0: "dimensions",
			1: "metrics",
			2: "columns",
		},
	}),

	created() {
		this.$nextTick(async () => {
			/**
			 * Store
			 * se obtienen los campos filtrado por entidad
			 */
			this.fields = await this.getFieldByView(this.$route?.name);

			/**
			 * Store
			 * se obtienen los campos seleccionados filtrado por entidad
			 */
			this.selected = await this.getSelectedByView(this.$route?.name);
		});
	},

	mounted() {},

	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),

		getType(): Boolean {
			return this.view_content[this.current_tab];
		},

		isSelectedAll(): Boolean {
			const tab: string = this.getType;
			return tab && this.selected && this.selected[tab]?.all;
		},

		getFieldsByCurrentTab() {
			const tab: string = this.getType;

			if (!this.fields) return [];

			if (!Object.entries(this.fields).length) return [];

			return tab ? this.fields[tab] : [];
		},

		getDefaultByCurrentTab() {
			const tab: string = this.getType;

			if (!this.fields) return [];

			if (!Object.entries(this.fields).length) return [];

			return tab ? this.fields?.hold : [];
		},

		getSelected: {
			get() {
				const tab: string = this.getType;
				return tab && this.selected ? this.selected[tab]?.data : [];
			},
			set(val: any) {
				const tab: string = this.getType;
				this.selected[tab].data = val;
			},
		},

		toggleSelectedAll() {
			return this.getFieldsByCurrentTab.some((d: any) => {
				const selected: Array<any> = this.getSelected;
				return !selected.includes(d.key);
			});
		},

		filteredFields() {
			if (!this.term) return this.getFieldsByCurrentTab;
			const filtered: Array<any> = this.getFieldsByCurrentTab.filter(
				(d: any) =>
					String(d.value)
						.toLowerCase()
						.includes(String(this.term).toLowerCase())
			);
			return filtered;
		},
	},

	methods: {
		async getFieldsByView(route_name: any) {
			return this.getFieldByView({ view: route_name });
		},

		/**
		 * Handlers
		 */
		handleUpdateTerm(event: string) {
			this.term = event;
		},

		handleToggleAll(event: any) {
			this.handleSelectAll(event);
		},

		handleSelected(event: any) {
			const tab: string = this.getType;
			if (!tab) return;
			this.selected[tab].data = event;
			this.verifyAllSelected(event);
		},

		handleVerify(event: any) {
			this.verifyAllSelected(event);
		},

		handleTabIndex(index: any) {
			this.current_tab = index;
		},

		handleSelectAll: function (event: any) {
			this.unSelectAllFields(event);
			const tab: string = this.getType;

			if (event) {
				this.getFieldsByCurrentTab.map((d: any) => {
					this.selected[tab].data.push(d.key.toString());
				});
			}

			if (tab === this.view_content[0]) {
				const _hold: Array<string> = this.getDefaultByCurrentTab;

				const _selected: Array<string> = this.selected[tab].data;

				this.selected[tab].data = union(_hold, _selected);
			}
		},

		/**
		 * Actions
		 */

		handleRemove(key: any) {
			const tab: string = this.getType;
			if (!this.selected[tab]?.data) {
				this.unSelectAllFields();
			}
			const index = this.selected[tab].data.findIndex(
				(d: any) => d === key
			);

			if (index > -1) {
				this.selected[tab].data.splice(index, 1);
				this.selected[tab].all = false;
				if (!this.selected[tab]?.data) {
					this.unSelectAllFields();
				}
			}
		},

		/**
		 * Getters
		 */

		getDimension(field: any) {
			return this.getFieldsByCurrentTab.find((f: any) => f.key === field)
				?.value;
		},

		/**
		 * Utils
		 */

		unSelectAllFields(selected: Boolean = false) {
			const tab: string = this.getType;
			this.selected[tab].all = selected;
			this.selected[tab].data = [];
		},

		verifyAllSelected: function (event: any) {
			if (event) {
				const tab: string = this.getType;
				this.selected[tab].all = !this.toggleSelectedAll;
			} else {
				this.unSelectAllFields();
			}
		},

		handleResetColumns() {
			this.$emit("reset-column");
		},
	},

	watch: {
		selected: {
			handler(val) {
				const tab: string = this.getType;
				this.$emit("change", {
					view: this.$route?.name,
					tab: tab,
					selected: val,
				});
			},
			deep: true,
		},
	},
});
