
// @ts-ignore
import CreateTabs from "@/components/Commons/CreateTabs.vue";
import Vue from "vue";

export default Vue.extend({
	name: "VerticalNav",
	props: {
		current_tab: {
			type: Number,
			default: 0
		}
	},
	components: { CreateTabs },
	data: () => ({}),
	created() {},
	async mounted() {},
	computed: {
		getItems() {
			return [
				{
					key: 0,
					tab: this.$t("customize.dimensions.name"),
					disabled: false,
					show: true,
					count: null,
					// @ts-ignore
					src: require(`@/assets/icons/dimensions_icon.png`)
				},
				{
					key: 1,
					tab: this.$t("customize.metrics.name"),
					disabled: false,
					show: true,
					count: null,
					// @ts-ignore
					src: require(`@/assets/icons/metrics_icon.png`)
				}
			];
		}
	},
	methods: {
		updateSelectedTabIndex(index: number) {
			this.$emit("change", index);
		}
	}
});
