
import Vue from 'vue';
// @ts-ignore
import Buttons from "@/views/Admin/Commons/Buttons.vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import AssociationTable from "@/views/Admin/Creatives/Associations/AssociationTable.vue";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import { CreativeAsso, ResultPaginateAsso } from "@/interfaces/creative";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { mapGetters, mapActions } from "vuex";
// @ts-ignore
import { isUndefined, isNull } from "lodash";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import { getHeaderAssoCreativeLine } from "@/utils/CustomizerTables.ts";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

const dictionary = {
	external_id: "external_id",
	line_item_external_id: "line_items.external_id",
	line_item_name: "line_items.name"
}

export default Vue.extend({
    name: "AssociationTableBase",
    props:{
		creative: {
			type: Object,
			default: function () {
				return {};
			},
		},
		readonly:{
			type:Boolean,
			default: false
		}
    },
    components:{
        Buttons,
        Alertize,
		AssociationTable
    },
    data: () => ({
		paginated: { page: 1, limit: 25 } as Paginated,
		filters: {},
		options: getDefaultSortingOption(),
		img_default: require(`@/assets/default_image.webp`)
	}),
    created(){},
    mounted(){
		this.$nextTick(async () => {
			await this.getPaginated();
		})
	},
    computed:{

		...mapGetters("creative", ["result_pag_assoc_line"]),

		getHeaders(){
			return getHeaderAssoCreativeLine();
		},

		getReadOnly(){
			return this.readonly;
		},

		getEntities(): CreativeAsso[] {
			const result: ResultPaginateAsso = this.result_pag_assoc_line;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},

        getSize() {
			return this.$t("show.creatives", {
				total: this.getEntities.length,
			});
		},

		getItems() {
			const items: Array<any> = [];
			this.getEntities.map((entity: any) => {
				const item: any = {
					id: entity?.id,
					external_id: entity?.external_id,
					creative_external_id: entity?.creative_external_id,
					campaign_item_id: entity?.campaign_external_id ? Number(entity?.campaign_external_id) : undefined,
					campaign_item_name: entity?.campaign_name,
					line_item_external_id: entity?.line_item_external_id ? Number(entity?.line_item_external_id) : undefined,
					line_item_name: entity?.line_item_name,
					active: !!entity?.line_item_active,
					line_item_active: this.getActiveText(entity?.line_item_active),
					start_date: entity?.start_date ? this.moment(entity?.start_date).format(DATE_TIME_FORMAT) : undefined,
					end_date: entity?.end_date ? this.moment(entity.end_date).format(DATE_TIME_FORMAT) : undefined,
					edited: false,
					new_start_date: undefined,
					new_end_date: undefined,
					valid: true,
				};
				items.push(item);
			});
			return items;
		},
    },
    methods:{
		...mapActions("creative", ["paginatedAssocLine"]),
		...mapActions("loading", ["setLoadingData"]),

		async getPaginated() {
			await this.setLoadingData(TypeLoading.loading);
			this.filters["creative_id"] = this.creative.id;
			await this.paginatedAssocLine({
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
			});
			await this.setLoadingData();
		},

		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated();
		},

		updatePaginate(data: any) {
			this.paginated.page = data;
		},

		getActiveText(active: Boolean) {
			return active ? "Active" : "Inactive";
		},

		async updateTable(){
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async updateParams(params: { filters: any; options: SortingOption }) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] = typeof params.value !== "undefined" ? params.value : "";
		},

		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			params.options.sort =  dictionary[params.options.sort];
			await this.updateParams({filters: this.filters, options: params.options});
		},
		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption();
			this.updatePaginate(1);
			await this.getPaginated();
		},

	},
	watch:{
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated();
			}
		},
	}
});
